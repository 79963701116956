import React from "react"
import {graphql, Link} from "gatsby"
import { Helmet } from 'react-helmet'

import Bio from "../components/bio"
import Layout from "../components/layout"
// import SEO from "../components/seo"
import {SEO, useSEO} from "gatsby-plugin-seo";
import {rhythm, scale} from "../utils/typography"

function renderNav(previous, next) {
    return <nav>
        <ul
            style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
            }}
        >
            <li>
                {previous && (
                    <Link to={previous.fields.slug} rel="prev">
                        ← {previous.frontmatter.title}
                    </Link>
                )}
            </li>
            <li>
                {next && (
                    <Link to={next.fields.slug} rel="next">
                        {next.frontmatter.title} →
                    </Link>
                )}
            </li>
        </ul>
    </nav>;
}

function renderArticle(post) {
    return <article>
        <header>
            <h1
                style={{
                    marginTop: rhythm(1),
                    marginBottom: 0,
                }}
            >
                {post.frontmatter.title}
            </h1>
            <p
                style={{
                    ...scale(-1 / 5),
                    display: `block`,
                    marginBottom: rhythm(1),
                }}
            >
                {post.frontmatter.date}
            </p>
        </header>
        <section dangerouslySetInnerHTML={{__html: post.html}}/>
        {/*<hr*/}
        {/*    style={{*/}
        {/*        marginBottom: rhythm(1),*/}
        {/*        width: "20%"*/}
        {/*    }}*/}
        {/*/>*/}
        {/*<footer>*/}
        {/*    <Bio/>*/}
        {/*</footer>*/}
        <div style={styles.bio}>
            <Bio style={styles.bio}/>
        </div>
    </article>;
}

const BlogPostTemplate = ({data, pageContext, location}) => {
    const post = data.markdownRemark
    const siteTitle = data.site.siteMetadata.title
    const {previous, next} = pageContext
    const {siteUrl} = useSEO();

    return (
        <Layout location={location} title={siteTitle}>
            <SEO
                title={post.frontmatter.title}
                description={post.frontmatter.description || post.excerpt}
                pagePath="/"
                schema={`{
              "@context": "http://schema.org",
              "@type": "WebPage",
              "mainEntity": {
                "@type": "Organization",
                "name": "Проект | ГДЕ Угроза?",
                "image": "${siteUrl}/assets/images/logo_illustration.jpg"
              }
            }`}
            />
            {renderArticle(post)}

            {renderNav(previous, next)}
        </Layout>
    )
}

const styles = {
    bio : {
        marginTop: "3.375rem" ,
        marginBottom: "4.375rem"
    },

    textMuted : {
        color: "#777"
    },
    container: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        height: "100vh",
        width: "100vw",
    },
    channelList: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
    },
    chat: {
        display: "flex",
        flex: 3,
        flexDirection: "column",
        borderWidth: "1px",
        borderColor: "#ccc",
        borderRightStyle: "solid",
        borderLeftStyle: "solid",
    },
    settings: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
    },
};


export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
